<template>
    <div>
      
      <ListPage
        ref="appTable"
        :endpoint="apiCall"
        :headers="headers"
        title="Expenses"
        :search="search"
        :showActionsColumn="true"
        :showViewButton="isAllowed('viewExpenses')"
        :showEditButton="isAllowed('editExpenses')"
        :showDeleteButton="isAllowed('deleteExpenses')"
         @view="navigateTo(`/app/expenses/view/${$event._id}`)"
        @add="navigateTo('/app/expenses/0')"
        @edit="navigateTo(`/app/expenses/${$event._id}`)" 
        @delete="deleteExpense($event._id)"
       
      >
        <!-- Custom Slots -->
        <template v-slot:txDate="{ item }">
          {{ item.txDate | dateFormat }}
        </template>
       
        <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
          <expense-card :expense="item" :icons="icons" @delete="deleteExpense"></expense-card>
        </template>
      </ListPage>
  
      <alert-message v-if="msg" :error="msg"></alert-message>
    </div>
  </template>
  
  <script>
  import ActionButton from '@/components/ActionButton'
  import appConstants from '@/utils/appConstants'
  import ExpenseCard from '@/components/ExpenseResponsiveCard.vue'
  import ListPage from '@/components/common/ListPage.vue'
  
  export default {
    components: {
      ActionButton,
      ExpenseCard,
      ListPage
    },
    data() {
      return {
        search: "",
        headers: [
          { text: "Expense Date", value: "txDate" },
          { text: "Mode", value: 'mode' },
          { text: "Type", value: 'txType' },
          { text: "Narration", value: 'narration' },
          { text: "Amount", value: 'txAmount', align: 'right' },
          { text: 'Actions', value: 'action', align: 'right' }
        ],
        items: [],
        icons: [
          { name: "mdi-eye", path: "/app/expenses/view/", permission: "viewExpenses" },
          { name: "mdi-pencil", path: "/app/expenses/", permission: "editExpenses" },
          { name: "mdi-delete", permission: "deleteExpenses" }
        ],
        apiCall: appConstants.EXPENSE_MANAGER_API,
        msg: null,
      };
    },
    methods: {
      async deleteExpense(id) {
        try {
          await this.deleteItem("Are you sure you want to delete this Expense?", `${appConstants.EXPENSE_MANAGER_API}/${id}`);
          this.items.splice(this.items.findIndex(rec => rec._id === id), 1);
          this.$refs.appTable.loadData(); 
        } catch (error) {
          this.handleError(error);
          this.msg = error.response.data;
        }
      },
      navigateTo(path) {
        this.$router.push(path);
      },
     
     
    }
  };
  </script>
  
  <style scoped>

  </style>
  